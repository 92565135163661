import { ReactElement } from 'react';
import { graphql } from 'gatsby';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import AddMinorConfirmOrSendInvitationFlow from 'components/add-minor-confirm-or-send-invitation-flow';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// Interface for Dependent Treated Data.

// Styles
import './confirm-details-page.style.scss';

// Main component
const AddMinorConfirmDetailsPage = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.AddAMinorDataQuery;
}): ReactElement => {
    // General
    const { t } = useTranslation();

    return (
        <WorkflowLayout
            backgroundImage={data.feathersBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t(`pages.profile.addFamilyMember.eyebrowText.minor`) }}
            anonymousOnly={false}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t(`pages.profile.addFamilyMember.eyebrowText.minor`)}
                title={t('pages.profile.addFamilyMember.confirmDetailsTitle')}
            >
                <AddMinorConfirmOrSendInvitationFlow />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};
export default AddMinorConfirmDetailsPage;

export const query = graphql`
    query AddMinorConfirmDetailsData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        feathersBackground: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
